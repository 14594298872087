import React from "react";
import { ThemeProvider } from "theme-ui";
import theme from "./DSL/theme";
import "./DSL/styles/main.css";

import { FirebaseProvider } from "./context/Firebase";

import "./DSL/icons/library";
import { DataProvider } from "./context/data";

import { AuthProvider } from "./context/auth";
import { BrowserRouter } from "react-router-dom";
import Router from "./Router";

function App() {
  return (
    <FirebaseProvider>
      <AuthProvider>
        <DataProvider>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </ThemeProvider>
        </DataProvider>
      </AuthProvider>
    </FirebaseProvider>
  );
}

export default App;
