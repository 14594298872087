import React, { useState, useEffect } from "react";
import { useAuthContext } from "../../app/context/auth";
import { MainBlock } from "../../app/DSL/components/layout/StyledComponents";
import { useData } from "../../app/context/data";
import PresenterView from "./PresenterView";
import ParticipantView from "./ParticipantView";
import { Spinner } from "theme-ui";
import { primary } from "../../app/DSL/theme";

export default function Main() {
  const {
    currentHotSeat,
    loading,
    currentSessionDetails,
    currentSession,
  } = useData();
  const [presenter, setPresenter] = useState();
  const [submitted, setSubmitted] = useState(false);
  const { user } = useAuthContext();

  useEffect(() => setPresenter(user && user.isAnonymous === false), [user]);
  useEffect(() => {
    if (
      currentHotSeat &&
      currentHotSeat.participantsSubmitted &&
      user &&
      user.uid
    ) {
      setSubmitted(currentHotSeat.participantsSubmitted.includes(user.uid));
    }
  }, [currentHotSeat, user]);

  const [view, setView] = useState("loading");

  useEffect(() => {
    if (loading.details || loading.session || loading.hotSeat) {
      setView("loading");
    } else if (
      Object.keys(currentSessionDetails).filter((k) => k !== "id").length === 0
    ) {
      setView("start");
    } else if (currentSession && currentHotSeat === null) {
      setView("select");
    } else if (currentSession && currentHotSeat && !submitted) {
      setView("vote");
    } else if (currentSession && currentHotSeat && submitted) {
      setView("end");
    }
  }, [
    submitted,
    user,
    currentHotSeat,
    currentSession,
    setView,
    loading,
    currentSessionDetails,
  ]);

  return (
    <MainBlock>
      {loading.details || loading.session || loading.hotSeat ? (
        <Spinner color={`${primary}.shade.500`} style={{ width: "100%" }} />
      ) : (
        <div>
          {presenter === true && <PresenterView view={view} />}
          {presenter === false && <ParticipantView view={view} />}
        </div>
      )}
    </MainBlock>
  );
}
