import React from "react";
import { Switch, Route } from "react-router-dom";
import { useAuthContext } from "./context/auth";
import Main from "../features/Main";
import Results from "../features/Results";
import History from "../features/History";
import Register from "../features/Auth/Register";
import Login from "../features/Auth/Login";
import { useFirebase } from "./context/Firebase";
import { Button } from "@theme-ui/components";
import { PageWrapper, Header } from "./DSL/components/layout/StyledComponents";
import HotSeats from "../features/History/HotSeats";

export default function Router() {
  const { user } = useAuthContext();
  const firebase = useFirebase();
  const adminRoutes = [
    { path: "/results", component: Results },
    { path: "/history", component: History },
    { path: "/history/:id", component: HotSeats },
  ];

  const createRoutes = (routes) => {
    return routes.map((route, idx) => <Route exact {...route} key={idx} />);
  };

  return (
    <>
      <Header>
        <img src="/logo.svg" alt="Zimmer Biomet" />
        <img className="hlogo" src="/hotseat.svg" alt="hotseat" />
        <div className="logout">
          {user && !user.isAnonymous ? (
            <Button
              onClick={() =>
                firebase.signOut().then(() => window.location.replace("/login"))
              }
            >
              Logout
            </Button>
          ) : (
            <div />
          )}
        </div>
      </Header>
      <PageWrapper>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/" component={Main} />
          {user && !user.isAnonymous && createRoutes(adminRoutes)}
          {user && <Route exact render={() => <div></div>} />}
        </Switch>
      </PageWrapper>
    </>
  );
}
