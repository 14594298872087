import React from "react";
import styled from "@emotion/styled";

const Votes = ({ questions, setScores, scores }) => {
  const updateScore = (value, score) => {
    setScores(prev => ({ ...prev, [value]: score }));
  };

  return (
    questions &&
    questions.map(({ title, value }, index) => {
      return (
        <Vote key={index}>
          <h4>{title}</h4>
          <div>
            <Option
              selected={scores[value] === 1}
              onClick={() => updateScore(value, 1)}
            >
              1
            </Option>
            <Option
              selected={scores[value] === 2}
              onClick={() => updateScore(value, 2)}
            >
              2
            </Option>
            <Option
              selected={scores[value] === 3}
              onClick={() => updateScore(value, 3)}
            >
              3
            </Option>
            <Option
              selected={scores[value] === 4}
              onClick={() => updateScore(value, 4)}
            >
              4
            </Option>
            <Option
              selected={scores[value] === 5}
              onClick={() => updateScore(value, 5)}
            >
              5
            </Option>
            <Option
              selected={scores[value] === 6}
              onClick={() => updateScore(value, 6)}
            >
              6
            </Option>
            <Option
              selected={scores[value] === 7}
              onClick={() => updateScore(value, 7)}
            >
              7
            </Option>
            <Option
              selected={scores[value] === 8}
              onClick={() => updateScore(value, 8)}
            >
              8
            </Option>
            <Option
              selected={scores[value] === 9}
              onClick={() => updateScore(value, 9)}
            >
              9
            </Option>
            <Option
              selected={scores[value] === 10}
              onClick={() => updateScore(value, 10)}
            >
              10
            </Option>
          </div>
        </Vote>
      );
    })
  );
};

export default Votes;

const Vote = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  border-bottom: 1px solid #d0d0d0;
  h4 {
    font-size: 20px;
    color: #58595b;
  }
  div {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-gap: 15px;
    justify-content: center;
    align-items: center;
    button {
      border-radius: 50%;
      width: 45px;
      height: 45px;
      font-size: 15px;
    }
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    h4 {
      margin: 10px 0;
    }
    div {
      grid-gap: 8px;
      button {
        width: 25px;
        height: 25px;
        font-size: 13px;
      }
    }
  }
`;

const Option = styled.button`
  background-color: ${props =>
    props.selected ? props.theme.colors.blue.shade["500"] : "#fff"};
  color: ${props => (props.selected ? "#fff" : "#58595b")};
  transition: all 0.3s ease;
  :hover {
    background-color: ${props => props.theme.colors.blue.shade["500"]};
    border-color: ${props => props.theme.colors.blue.shade["500"]};
    color: #fff;
    cursor: pointer;
  }
  :focus {
    outline: none;
  }
`;
