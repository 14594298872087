import React from "react";
import ParticipantVote from "./ParticipantVote";
import styled from "@emotion/styled";

export default function ParticipantView({ view }) {
  return {
    start: <Start />,
    select: <NoParticipant />,
    vote: (
      <SectionWrapper>
        <ParticipantVote />
      </SectionWrapper>
    ),
    end: <End />,
    loading: <Loading />,
  }[view];
}

const Start = () => {
  return (
    <SectionContainer>
      <div>
        <div className="image-wrapper">
          <img src="/hourglass-half-light.svg" alt="hourglass" />
        </div>
        <h2>Welcome to the Zimmer Biomet</h2>
        <h1 className="title">Hotseat Poll</h1>
        <h2>Please be patient, the session will start soon</h2>
      </div>
    </SectionContainer>
  );
};

const NoParticipant = () => {
  return (
    <SectionContainer>
      <div>
        <div className="image-wrapper">
          <img src="/hourglass-half-light.svg" alt="hourglass" />
        </div>
        <h2>Please wait while the presenter chooses a participant</h2>
      </div>
    </SectionContainer>
  );
};

const End = () => {
  return (
    <SectionContainer>
      <div>
        <div className="image-wrapper">
          <img src="/thumbs-up-light.svg" alt="hourglass" />
        </div>
        <h1>Thank You</h1>
        <h2>We appreciate your feedback</h2>
      </div>
    </SectionContainer>
  );
};

const Loading = () => {
  return (
    <SectionContainer>
      <div>
        <div className="image-wrapper">
          <img src="/hourglass-half-light.svg" alt="hourglass" />
        </div>
        <h1>Loading</h1>
        <h2>Please be patient</h2>
      </div>
    </SectionContainer>
  );
};

const SectionContainer = styled.div`
  color: #58595b;
  text-align: center;
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  .image-wrapper {
    background-color: #0082c9;
    border-radius: 50%;
    height: 140px;
    width: 140px;
    animation: updown 3s ease infinite;
    margin: auto;
    margin-bottom: 30px;
  }
  img {
    width: 60px;
    margin-top: 30px;
  }
  h1 {
    margin-bottom: 10px;
    font-size: 4vw;
  }
  h2 {
    margin-bottom: 10px;
    font-size: 20px;
  }
  .title {
    font-size: 6vw;
    margin-top: 0px;
  }
  @keyframes updown {
    0% {
      transform: translateY(-5%);
    }

    50% {
      transform: translateY(5%);
    }

    100% {
      transform: translateY(-5%);
    }
  }
`;

const SectionWrapper = styled.div`
  color: #58595b;
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;

  h1,
  h2,
  h3,
  h4,
  p {
    text-align: center;
  }
  .name {
    margin-bottom: 20px;
  }
`;
