import React from "react";
import { Card, Heading } from "@theme-ui/components";
import { ResponsiveLine } from "@nivo/line";
import styled from "@emotion/styled";
import { CardHeadingGrid } from "../../app/DSL/components/layout/StyledComponents";

export default function ResultCard({ seat, current, numSubmitted }) {
  return (
    <Card style={{ marginBottom: "2em", width: "100%" }}>
      {current && (
        <CardHeadingGrid key="current">
          <h2>Current Hot Seat</h2>
          <h3 className="end">
            {numSubmitted}{" "}
            {numSubmitted === 1 ? "participant has" : "participants have"}{" "}
            submitted
          </h3>
        </CardHeadingGrid>
      )}

      <Heading color={seat.color} style={{ marginBottom: "1em" }}>
        {seat.id}
      </Heading>
      <Grid>
        <div style={{ display: "block", height: "25vh" }}>
          <ResponsiveLine
            data={[seat]}
            margin={{ top: 10, right: 50, bottom: 30, left: 60 }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: 0,
              max: 10,
              stacked: false,
              reverse: false,
            }}
            curve="cardinal"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: "bottom",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Question",
              legendOffset: 36,
              legendPosition: "middle",
            }}
            axisLeft={{
              orient: "left",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Score Average",
              legendOffset: -40,
              legendPosition: "middle",
            }}
            colors={(d) => d.color}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabel="y"
            pointLabelYOffset={-12}
            enableArea={true}
            useMesh={true}
            legends={[]}
          />
        </div>

        <div>
          <Heading as="h2" style={{ textAlign: "center" }}>
            Total:
          </Heading>
          <Heading as="h2" style={{ textAlign: "center" }}>
            {seat.total === 0 ? "No scores" : `${Math.round(seat.total)} /50`}
          </Heading>
        </div>
      </Grid>
    </Card>
  );
}
const Grid = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  column-gap: 1em;
  align-items: center;
`;
