import styled from "@emotion/styled";
import { Card } from "@theme-ui/components";

export const PageWrapper = styled.div`
  min-height: 90vh;
  display: grid;
  justify-items: center;
`;

export const MainBlock = styled(Card)`
  width: 80vw;
  min-height: 80vh;
  margin: 2em 0;
`;

export const CardHeadingGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-bottom: 1em;
  a,
  button,
  .end {
    justify-self: end;
  }
`;

export const Header = styled.div`
  background-color: #fff;
  padding: 10px 10vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 10vh;
  width: 100vw;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  align-items: center;

  img {
    width: 16vw;
    justify-items: center;
    align-items: center;
    display: grid;
  }
  .hlogo {
    width: 13vw;
    margin: auto;
  }
  h1 {
    color: #58595b;
    margin: 0;
    justify-items: center;
    align-items: center;
    display: grid;
    font-weight: 400;
    font-size: 25px;
    text-align: center;
  }
  .logout {
    justify-items: end;
    align-items: center;
    display: grid;
  }
  @media (max-width: 600px) {
    img {
      width: 26vw;
    }
  }
`;
