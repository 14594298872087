import React, { useState, useEffect } from "react";
import {
  Card,
  Heading,
  Label,
  Input,
  Divider,
  Button,
  Message
} from "@theme-ui/components";
import styled from "@emotion/styled";
import { InputWrap } from "../../app/DSL/components/form/StyledInput";
import { useFirebase } from "../../app/context/Firebase";
import { NavLink } from "react-router-dom";
import { useAuthContext } from "../../app/context/auth";

export default function Register({ history }) {
  const [displayName, setDisplayName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [valid, setValid] = useState(false);
  const [error, setError] = useState();
  const auth = useAuthContext();
  const firebase = useFirebase();

  const validateEmail = x => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(x);
  };

  useEffect(() => {
    const emailValid = validateEmail(email);
    setValid(emailValid && password && displayName ? true : false);
  }, [email, password, displayName]);

  useEffect(() => {
    if (auth.user && !auth.user.isAnonymous) {
      history.push("/");
    }
  }, [auth, history]);

  const handleLogin = () => {
    firebase
      .signup(email, password)
      .then(result => {
        return result.user
          .updateProfile({
            displayName
          })
          .then(() => window.location.replace("/"));
      })
      .catch(err => setError(err));
  };
  return (
    <LoginCard>
      <Heading as="h1">Hot seats</Heading>
      <Heading as="h2">Register</Heading>
      <Divider />
      <InputWrap>
        <Label>Display Name</Label>
        <Input
          onChange={e => setDisplayName(e.target.value)}
          type="text"
          placeholder="Joe Soap"
        />
      </InputWrap>
      <InputWrap>
        <Label>Email Address</Label>
        <Input
          onChange={e => setEmail(e.target.value)}
          type="email"
          placeholder="example@zimmerbiomet.co.za"
        />
      </InputWrap>
      <InputWrap>
        <Label>Password</Label>
        <Input
          onChange={e => setPassword(e.target.value)}
          type="password"
          placeholder="••••••"
        />
      </InputWrap>
      {error && <Message>{error.message}</Message>}
      <Divider />
      <Button
        variant={!valid ? "disabled" : "primary"}
        onClick={() => handleLogin()}
      >
        Register
      </Button>
      <p>
        Already have an account? <NavLink to="/login">Login</NavLink>
      </p>
    </LoginCard>
  );
}

const LoginCard = styled(Card)`
  width: 60%;
`;
