import React, { useMemo } from "react";
import { useData } from "../../app/context/data";
import { Button, Spinner, Heading } from "theme-ui";
import ParticipantVote from "./ParticipantVote";
import SelectParticipant from "./SelectParticipant";
import styled from "@emotion/styled";
import { primary } from "../../app/DSL/theme";
import Results from "../Results";
import { CardHeadingGrid } from "../../app/DSL/components/layout/StyledComponents";
import { NavLink } from "react-router-dom";
import ResultGraphs from "../Results/ResultGraphs";

export default function PresenterView({ view }) {
  const { currentHotSeat, startSession, endHotSeat } = useData();

  const numSubmitted = useMemo(() => {
    return currentHotSeat && currentHotSeat.participantsSubmitted
      ? currentHotSeat.participantsSubmitted.length
      : 0;
  }, [currentHotSeat]);

  return (
    <>
      {
        {
          start: (
            <SectionWrapper className="section-wrapper">
              <div>
                <Button
                  onClick={() => {
                    startSession();
                  }}
                >
                  Start new session
                </Button>
              </div>
            </SectionWrapper>
          ),
          select: (
            <SectionWrapper>
              <SelectParticipant />
            </SectionWrapper>
          ),
          vote: (
            <>
              <CardHeadingGrid key="top">
                <Heading>Current Session Results</Heading>

                <Button onClick={() => endHotSeat()}>
                  End Current Hot Seat
                </Button>
              </CardHeadingGrid>
              <ResultGraphs />
            </>
          ),
          loading: (
            <Spinner color={`${primary}.shade.500`} style={{ width: "100%" }} />
          ),
        }[view]
      }
    </>
  );
}

const SectionWrapper = styled.div`
  color: #58595b;
  width: 100%;
  min-height: 80vh;
  display: grid;
  justify-content: center;
  align-items: center;

  h1,
  h2,
  h3,
  h4,
  p {
    text-align: center;
  }
  .name {
    margin-bottom: 20px;
  }
`;
