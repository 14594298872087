export const questions = [
  {
    title: "Opening",
    value: "opening"
  },
  {
    title: "Teach",
    value: "teach"
  },
  {
    title: "Tailor",
    value: "tailor"
  },
  {
    title: "Take control",
    value: "control"
  },
  {
    title: "Getting a result",
    value: "result"
  }
];
