export const participants = [
  "Charl Coetzer",
  "Werner Griesel",
  "Ancke Oelofse",
  "Willem Serfontein",
  "Jean Strauss",
  "Suzette Vorster",
  "Nicholas Da Silva",
  "Nathan Dale Johnston",
  "Alastair Laurence",
  "Urscheluande Marais",
  "Craig Pepper",
  "Chris Regel",
  "Le Roux Van Aarde",
  "Graeme Brebner",
  "John Haskins",
  "Ryan Mash",
  "Larry Oosthuyzen",
  "Keagan Rhys Robinson",
  "Mark Sivell",
  "Chris Venter",
  "Donald Froneman",
  "Shanene Beyers",
  "Lionel Kraayeveld",
  "Byron Labuschagne",
  "Brandon Le Roux",
  "Neil Lourens",
  "Ricardo Louw",
  "Kathelo Gavin Manyelo",
  "Morne Johann Odendaal",
  "Rudi Rademeyer",
  "Jacques Smith",
  "Hilda Van der Bank",
  "Morne Van Der Merwe",
  "Brendon Wecke",
  "Johan Zwiegelaar",
  "John Craig Allchurch",
  "Johannes De Klerk",
  "Mariessa Breed",
  "Anke Dedekind",
  "Crisna Harmse",
  "Alexia Lombard",
  "William Mkhathswa",
  "Piet Thulani Ndlovu",
  "Catherine Smith",
  "Christine Van Niekerk",
  "Warren Forrester-Jones",
  "Shaun Whittal",
  "Christopher Zithwana",
  "Johan Kilian",
  "Jaco Erasmus",
  "Zwelethu Mbizane",
  "Jabulane Mkhasibe",
];
