import React, { useMemo } from "react";
import {
  MainBlock,
  CardHeadingGrid
} from "../../app/DSL/components/layout/StyledComponents";
import { Button, Spinner, Heading } from "theme-ui";
import { useData } from "../../app/context/data";
import Table from "../../app/DSL/components/elements/Table";
import {
  HistoryTableContainer,
  RoundButton
} from "../../app/DSL/components/elements/styled";
import { format } from "date-fns/esm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import { primary } from "../../app/DSL/theme";

export default function History({ history }) {
  const { startSession, sessions, currentSession } = useData();

  const columns = useMemo(
    () => [
      {
        Header: "Run By",
        id: "runBy",
        accessor: row => row.runBy && row.runBy.name,
        filter: "fuzzyText"
      },
      {
        Header: "Date",
        id: "date",
        accessor: row => (
          <>{row.date && format(row.date.toDate(), "dd MMM yyyy")}</>
        ),
        filter: "fuzzyText"
      },
      {
        Header: "Time",
        id: "time",
        accessor: row => (
          <>
            {row.startTime && format(row.startTime.toDate(), "hh:mm a")} -{" "}
            {row.endTime && format(row.endTime.toDate(), "hh:mm a")}
          </>
        ),
        filter: "fuzzyText"
      },
      {
        Header: "View Hot Seats",
        id: "view",
        accessor: row => (
          <NavLink to={`/history/${row.id}`}>
            <RoundButton>
              <FontAwesomeIcon icon={["fal", "chair-office"]} />
            </RoundButton>
          </NavLink>
        ),
        disableFilters: true,
        disableSortBy: true
      }
    ],
    []
  );

  return (
    <MainBlock>
      {sessions ? (
        [
          <CardHeadingGrid key="top">
            <Heading>Past Sessions</Heading>
            {currentSession ? (
              <NavLink to="/results">
                <Button>View Current Session</Button>
              </NavLink>
            ) : (
              <Button
                onClick={() => {
                  startSession().then(() => {
                    history.push("/");
                  });
                }}
              >
                Start new session
              </Button>
            )}
          </CardHeadingGrid>,

          <HistoryTableContainer key="table">
            <Table
              emptyMsg="No users"
              columns={columns}
              data={sessions}
              defaultRows={5}
            />
          </HistoryTableContainer>
        ]
      ) : (
        <Spinner color={`${primary}.shade.500`} style={{ width: "100%" }} />
      )}
    </MainBlock>
  );
}
