import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faFolderOpen,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faChairOffice
} from "@fortawesome/pro-light-svg-icons";

library.add(
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faChairOffice
);

export {
  faFolderOpen,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faChairOffice
};
