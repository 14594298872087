import "./styles/main.css";
import button from "./styles/button";
import colours from "./styles/colours";
import forms from "./styles/form";

export const primary = "blue";

export default {
  colors: { ...colours, background: colours.tusk.shade["200"] },
  ...forms,
  fonts: {
    body: '"Source Sans Pro", sans-serif',
    heading: "inherit",
    monospace: "Menlo, monospace"
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700
  },
  cards: {
    primary: {
      width: "[ 1, 1, 1/2 ]",
      padding: "2em",
      borderRadius: 5,
      boxShadow: "1px 1px 5px rgba(0, 0, 0, .1)",
      bg: "white"
    }
  },
  buttons: {
    primary: {
      ...button,
      backgroundColor: `${primary}.shade.500`,
      color: `${primary}.font.500`,
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: `${primary}.shade.900`
      }
    },
    secondary: {
      ...button,
      backgroundColor: "tusk.shade.500",
      color: `${primary}.shade.500`
    },
    outline: {
      ...button,
      backgroundColor: "transparent",
      border: "2px",
      borderStyle: "solid",
      borderColor: `${primary}.shade.500`,
      color: `${primary}.shade.500`,
      fontWeight: "bold",
      "&:hover": {
        borderColor: `${primary}.shade.900`,
        backgroundColor: `${primary}.shade.900`,
        color: `${primary}.font.900`
      }
    },
    disabled: {
      fontWeight: "bold",
      backgroundColor: "tusk.shade.900",
      border: "2px",
      borderStyle: "solid",
      borderColor: "tusk.shade.900",
      color: "white",
      pointerEvents: "none"
    }
  },
  images: {
    login: {
      width: 150,
      height: 150,
      marginBottom: "2em"
    }
  },
  messages: {
    warning: {
      backgroundColor: "warning.shade.100",
      color: "warning.shade.500"
    }
  }
};
