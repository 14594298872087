import React from "react";
import {
  MainBlock,
  CardHeadingGrid,
} from "../../app/DSL/components/layout/StyledComponents";
import { Heading, Button } from "@theme-ui/components";
import { NavLink } from "react-router-dom";
import ResultGraphs from "./ResultGraphs";

export default function Results() {
  return (
    <MainBlock>
      <CardHeadingGrid key="top">
        <Heading>Current Session Results</Heading>

        <NavLink to="/results">
          <Button>View Past Sessions</Button>
        </NavLink>
      </CardHeadingGrid>
      <ResultGraphs />
    </MainBlock>
  );
}
