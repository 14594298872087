import React, { useState, useEffect, useMemo } from "react";
import { useData } from "../../app/context/data";
import {
  MainBlock,
  CardHeadingGrid
} from "../../app/DSL/components/layout/StyledComponents";
import styled from "@emotion/styled";
import { Heading, Button, Spinner } from "theme-ui";
import { format } from "date-fns/esm";
import { NavLink } from "react-router-dom";
import { questions } from "../../app/context/data/questions";
import { primary } from "../../app/DSL/theme";
import ResultCard from "../Results/ResultCard";

export default function HotSeats({ match }) {
  const [hotSeats, setHotSeats] = useState([]);
  const [session, setSession] = useState({});

  const { getHotSeats, sessions, getAverages } = useData();

  useEffect(() => {
    if (match.params.id) {
      setSession(
        sessions && sessions.length > 0
          ? sessions.find(s => s.id === match.params.id)
          : {}
      );
      getHotSeats(match.params.id).then(setHotSeats);
    }
  }, [match, getHotSeats, sessions]);

  const data = useMemo(() => {
    const average = getAverages(
      hotSeats && hotSeats.length > 0 ? hotSeats : []
    );

    return average.map((seat, idx) => ({
      id: seat.name,
      color: "#009AF0",
      total: seat.total,
      data: questions.map(({ title, value }) => ({
        x: title,
        y: seat.averages[value]
      }))
    }));
  }, [getAverages, hotSeats]);
  return (
    <MainBlock>
      {session.date ? (
        [
          <CardHeadingGrid key="top">
            <Heading>
              Session on {format(session.date.toDate(), "dd MMM yyyy hh:mm a")}
            </Heading>
            <NavLink to="/history">
              <Button>Back to history</Button>
            </NavLink>
          </CardHeadingGrid>,

          <Grid key="grid">
            {data
              .sort((a, b) => b.total - a.total)
              .map((seat, idx) => (
                <ResultCard seat={seat} key={idx} />
              ))}
          </Grid>
        ]
      ) : (
        <Spinner color={`${primary}.shade.500`} style={{ width: "100%" }} />
      )}
    </MainBlock>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1em;
  margin-top: 1em;
`;
