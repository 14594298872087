import React, { useState, useEffect, useMemo } from "react";
import { Button } from "@theme-ui/components";
import Votes from "./Votes";
import { useData } from "../../app/context/data";
import styled from "@emotion/styled";
import { useAuthContext } from "../../app/context/auth";
import { questions } from "../../app/context/data/questions";

const ParticipantVote = () => {
  const [total, setTotal] = useState(0);
  const { sendVote, endHotSeat, currentHotSeat } = useData();
  const [scores, setScores] = useState({
    opening: 0,
    teach: 0,
    tailor: 0,
    control: 0,
    result: 0
  });

  const allVoted = useMemo(() => Object.values(scores).every(s => s > 0), [
    scores
  ]);

  useEffect(() => {
    setTotal(Object.values(scores).reduce((total, score) => (total += score)));
  }, [scores, setTotal]);

  console.log("QUESTIONS", questions);

  const { user } = useAuthContext();

  return (
    <div>
      <h1>
        You are currently assessing: {currentHotSeat && currentHotSeat.name}
      </h1>
      <Votes questions={questions} setScores={setScores} scores={scores} />
      <h2>Total: {total}/50</h2>
      <BottomSection>
        {user && !user.isAnonymous && (
          <Button className="endButton" onClick={() => endHotSeat()}>
            End Hot Seat
          </Button>
        )}

        <Button
          className="submitButton"
          variant={allVoted ? "primary" : "disabled"}
          onClick={() => sendVote(scores)}
        >
          Submit
        </Button>
      </BottomSection>
    </div>
  );
};

export default ParticipantVote;

const BottomSection = styled.div`
  text-align: center;
  .submitButton {
    margin: 10px;
    width: 135px;
  }
  .endButton {
    margin: 10px;
    width: 135px;
    background-color: #989898;
    :hover {
      background-color: #333;
    }
    /* color: #333; */
  }
`;
