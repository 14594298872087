import styled from "@emotion/styled";
import { Button } from "@theme-ui/components";

export const RoundButton = styled(Button)`
  border-radius: 50% !important;
  text-align: center;
  padding: 0;
  width: 40px;
  height: 40px;
`;

export const InputPlaceholder = styled.div`
  line-height: 1.214286em;
  color: transparent;
  padding: 0.67857143em 0;
  margin: 0.5em 0;
  min-width: 100px;
`;

export const TableContainer = styled.div`
  padding: 1rem;

  table {
    width: 100%;
    border-spacing: 0;
    th,
    td {
      border-bottom: 1px solid rgba(74, 74, 74, 0.1);
      text-align: left;
      margin: 0;
      padding: 0.5rem;
    }
    th span {
      svg {
        margin: auto 10px;
      }
    }
    input {
      margin: 0.5em 0;
      font-weight: normal;
    }
  }

  .centered {
  }
  .pagination {
    margin: 2em auto 0 auto;
    display: grid;
    grid-template-columns: 40px 40px 1fr 40px 40px;
    align-items: center;
    grid-column-gap: 0.5em;
    .controls {
      display: grid;
      grid-template-columns: 100px 5px 100px 100px;
      grid-row-gap: 0.5em;
      margin: 0 1em;
      justify-self: center;
      justify-items: center;
      align-items: center;
      input {
        margin-right: 0;
      }
      div {
        width: 100%;
        grid-column: 1 / 5;
      }
      span {
        margin: 0 0.5em;
      }
    }
  }
`;

export const EmptyTableState = styled.td`
  text-align: center !important;
  color: #c4c4c4;
  font-weight: 700;
  padding: 2em !important;
  h3.ui.header {
    color: #c4c4c4;
    margin: 0.5em;
  }
  p {
    margin-top: 0.5em;
  }
`;

export const EmptyState = styled.div`
  text-align: center !important;
  color: #c4c4c4;
  font-weight: 700;
  h3.ui.header {
    color: #c4c4c4;
    margin: 0.5em;
  }
  p {
    margin-top: 0.5em;
  }
`;

export const ActionsTableContainer = styled(TableContainer)`
  table {
    td,
    th {
      /* changing width of last column for actions */
      :last-child {
        span {
          display: grid;
          grid-template-columns: repeat(2, 40px);
          column-gap: 10px;
        }
      }
    }
  }
`;

export const HistoryTableContainer = styled(TableContainer)`
  table {
    td,
    th {
      /* changing width of last column for actions */
      :last-child {
        text-align: right;
      }
    }
  }
`;
