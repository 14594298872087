import styled from "@emotion/styled";
import { primary } from "../../theme";

export const InputWrap = styled.div`
  box-sizing: border-box;
  clear: both;
  font-size: 1rem;
  position: relative;
  text-align: left;
  margin: 1em 0;
  label {
    font-size: 0.9em;
  }
  input,
  div {
    margin: 0.5em 0;
    border-color: ${props => {
      switch (props.state) {
        case "error":
          return props.theme.colors.warning.shade["300"];

        default:
          break;
      }
    }}!important;

    padding-left: ${props =>
      props.icon === "left" || props.icon === "both"
        ? " calc(2.5em + 1px)"
        : "auto"};

    padding-right: ${props =>
      props.icon === "right" || props.icon === "both"
        ? " calc(2.5em + 1px)"
        : "auto"};
  }
  svg {
    outline: 0;
    background-color: transparent !important;
  }
`;

export const IconLeft = styled.span`
  left: 0;
  height: 2.5em;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 2.5em;
  z-index: 4;
`;

export const IconRight = styled.span`
  right: 0;
  height: 2.5em;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 2.5em;
  z-index: 4;
  padding: 2.5px;
  color: ${props => props.theme.colors[primary].shade["500"]};
  text-align: right;
`;
