import React, { createContext, useContext, useState, useEffect } from "react";
import { transformDocSnap, transformCollectionSnap } from "../helpers";
import { useFirebase } from "../Firebase";
import { useAuthContext } from "../auth";

const DataContext = createContext({});

export const DataProvider = ({ children }) => {
  const firebase = useFirebase();
  const { user } = useAuthContext();
  const [sessions, setSessions] = useState();
  const [currentSessionDetails, setCurrentSessionDetails] = useState({});
  const [currentSession, setCurrentSession] = useState();
  const [currentHotSeat, setCurrentHotSeat] = useState();
  const [loading, setLoading] = useState({
    details: true,
    session: true,
    hotSeat: true
  });
  const [currentSessionHotSeats, setCurrentSessionHotSeats] = useState([]);

  useEffect(() => {
    const unsubscribe = firebase.db.collection("sessions").onSnapshot(snap => {
      setSessions(
        transformCollectionSnap(snap).filter(s => s.id !== "currentSession")
      );
      setLoading(prev => ({ ...prev, details: false }));
    });
    return () => unsubscribe();
  }, [firebase]);

  useEffect(() => {
    const unsubscribe = firebase.db
      .collection("sessions")
      .doc("currentSession")
      .onSnapshot(snap => {
        setCurrentSessionDetails(transformDocSnap(snap));
        setLoading(prev => ({ ...prev, details: false }));
      });
    return () => unsubscribe();
  }, [firebase]);

  useEffect(() => {
    const unsubscribe = currentSessionDetails.sessionId
      ? firebase.db
          .collection("sessions")
          .doc(currentSessionDetails.sessionId)
          .onSnapshot(snap => {
            setCurrentSession(transformDocSnap(snap));
            setLoading(prev => ({ ...prev, session: false }));
          })
      : () => {
          console.log("waiting for session");
        };
    return () => unsubscribe();
  }, [currentSessionDetails, firebase]);

  useEffect(() => {
    const unsubscribe = currentSessionDetails.sessionId
      ? firebase.db
          .collection("sessions")
          .doc(currentSessionDetails.sessionId)
          .collection("hotSeats")
          .onSnapshot(snap => {
            setCurrentSessionHotSeats(transformCollectionSnap(snap));
          })
      : () => {
          console.log("waiting for session");
        };
    return () => unsubscribe();
  }, [currentSessionDetails, firebase]);

  useEffect(() => {
    if (!currentSessionDetails.sessionId) {
      setCurrentSession(null);
      setLoading(prev => ({ ...prev, session: false }));
    }
    if (!currentSessionDetails.hotSeatId) {
      setCurrentHotSeat(null);
      setLoading(prev => ({ ...prev, hotSeat: false }));
    }
  }, [currentSessionDetails]);

  useEffect(() => {
    const unsubscribe = currentSessionDetails.hotSeatId
      ? firebase.db
          .collection("sessions")
          .doc(currentSessionDetails.sessionId)
          .collection("hotSeats")
          .doc(currentSessionDetails.hotSeatId)
          .onSnapshot(snap => {
            setCurrentHotSeat(transformDocSnap(snap));
            setLoading(prev => ({ ...prev, hotSeat: false }));
          })
      : () => {
          console.log("waiting for hot seat");
        };
    return () => unsubscribe();
  }, [currentSessionDetails, firebase]);

  const getHotSeats = async sessionId => {
    const snap = await firebase.db
      .collection("sessions")
      .doc(sessionId)
      .collection("hotSeats")
      .get();
    const data = transformCollectionSnap(snap);
    return data;
  };

  const startSession = () => {
    firebase.db
      .collection("sessions")
      .add({
        date: new Date(),
        startTime: new Date(),
        runBy: { name: user.displayName, email: user.email, id: user.uid }
      })
      .then(session =>
        firebase.db
          .collection("sessions")
          .doc("currentSession")
          .set({ sessionId: session.id })
      );
  };

  const startHotSeat = participant => {
    firebase.db
      .collection("sessions")
      .doc(currentSessionDetails.sessionId)
      .collection("hotSeats")
      .add({
        name: participant,
        participantsSubmitted: [],
        scores: []
      })
      .then(hotSeat =>
        firebase.db
          .collection("sessions")
          .doc("currentSession")
          .update({ hotSeatId: hotSeat.id })
      );
  };

  const endHotSeat = () => {
    firebase.db
      .collection("sessions")
      .doc("currentSession")
      .update({ hotSeatId: firebase.firestore.FieldValue.delete() });
  };

  const endSession = () => {
    firebase.db
      .collection("sessions")
      .doc(currentSessionDetails.sessionId)
      .update({ endTime: new Date() });
    firebase.db
      .collection("sessions")
      .doc("currentSession")
      .delete();
  };

  const sendVote = scores => {
    firebase.db
      .collection("sessions")
      .doc(currentSessionDetails.sessionId)
      .collection("hotSeats")
      .doc(currentSessionDetails.hotSeatId)
      .update({
        scores: firebase.firestore.FieldValue.arrayUnion(scores),
        participantsSubmitted: firebase.firestore.FieldValue.arrayUnion(
          user.uid
        )
      });
  };

  const getAverageScoresForHotseat = ({ scores }) => {
    const numberOfScores = scores ? scores.length : 0;
    const scoresTotal = scores
      ? scores.reduce(
          (acc, s) => {
            Object.entries(s).forEach(([question, score]) => {
              acc[question] += score;
            });
            return acc;
          },
          {
            opening: 0,
            teach: 0,
            tailor: 0,
            control: 0,
            result: 0
          }
        )
      : {
          opening: 0,
          teach: 0,
          tailor: 0,
          control: 0,
          result: 0
        };
    if (numberOfScores > 0) {
      for (const question in scoresTotal) {
        scoresTotal[question] /= numberOfScores;
      }
    }
    return scoresTotal;
  };

  const getAverages = hotSeats => {
    return hotSeats
      .map(hs => ({
        ...hs,
        averages: getAverageScoresForHotseat(hs)
      }))
      .map(hs => ({
        ...hs,
        total: Object.values(hs.averages).reduce((tot, num) => (tot += num))
      }));
  };
  return (
    <DataContext.Provider
      value={{
        currentSessionDetails,
        currentHotSeat,
        currentSession,
        sessions,
        startSession,
        startHotSeat,
        endHotSeat,
        endSession,
        sendVote,
        loading,
        currentSessionHotSeats,
        getAverages,
        getHotSeats
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const DataConsumer = DataContext.Consumer;

export const useData = () => useContext(DataContext);
