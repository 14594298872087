import React, { useMemo } from "react";
import { useData } from "../../app/context/data";
import { questions } from "../../app/context/data/questions";
import ResultCard from "./ResultCard";
import styled from "@emotion/styled";
import { Spinner } from "@theme-ui/components";
import { primary } from "../../app/DSL/theme";

export default function ResultGraphs() {
  const { getAverages, currentSessionHotSeats, currentHotSeat } = useData();

  const numSubmitted = useMemo(() => {
    return currentHotSeat && currentHotSeat.participantsSubmitted
      ? currentHotSeat.participantsSubmitted.length
      : 0;
  }, [currentHotSeat]);

  const currentData = useMemo(() => {
    const average = currentHotSeat ? getAverages([currentHotSeat]) : [];
    return average.map((seat) => ({
      id: seat.name,
      color: "#009AF0",
      total: seat.total,
      data: questions.map(({ title, value }) => ({
        x: title,
        y: seat.averages[value],
      })),
    }));
  }, [currentHotSeat, getAverages]);

  const data = useMemo(() => {
    const average =
      currentSessionHotSeats && currentSessionHotSeats.length > 0
        ? getAverages(
            currentSessionHotSeats.filter((hs) =>
              currentHotSeat ? hs.id !== currentHotSeat.id : true
            )
          )
        : [];

    return average.map((seat, idx) => ({
      id: seat.name,
      color: "#009AF0",
      total: seat.total,
      data: questions.map(({ title, value }) => ({
        x: title,
        y: seat.averages[value],
      })),
    }));
  }, [getAverages, currentSessionHotSeats, currentHotSeat]);
  return currentSessionHotSeats && currentSessionHotSeats.length > 0 ? (
    [
      currentData.map((seat, idx) => (
        <ResultCard
          seat={seat}
          key={idx}
          current={true}
          numSubmitted={numSubmitted}
        />
      )),
      <Grid>
        {data
          .sort((a, b) => b.total - a.total)
          .map((seat, idx) => (
            <ResultCard seat={seat} key={idx} />
          ))}
      </Grid>,
    ]
  ) : (
    <Spinner color={`${primary}.shade.500`} style={{ width: "100%" }} />
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1em;
  row-gap: 1em;
`;
