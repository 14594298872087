import React, { useState, useEffect, useContext, createContext } from "react";
import { useFirebase } from "../Firebase";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const firebase = useFirebase();

  const [authState, setAuthState] = useState({
    user: undefined,
    userId: undefined
  });

  /* const handleRedirect = authUser => {
    if (
      authUser &&
      !authUser.isAnonymous &&
      (window.location.pathname === "/login" ||
        window.location.pathname === "/register")
    ) {
      window.location.replace("/");
    }
  }; */

  useEffect(() => {
    firebase.checkUserAuth(async authUser => {
      //handleRedirect(authUser);
      if (authUser) {
        console.log(authUser.uid);
        setAuthState(prev => ({
          ...prev,
          user: authUser,
          userId: authUser.uid
        }));
      } else {
        firebase.anonymousSignIn().catch(function(error) {
          // Handle Errors here.
          console.log(error);

          // ...
        });
      }
    });
  }, [firebase]);

  return (
    <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
