import * as firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";
import config from "./config";

// Initialize Firebase
firebase.initializeApp(config);

const auth = firebase.auth();
const db = firebase.firestore();
const firestore = firebase.firestore;

const Firebase = {
  /////////////////////// AUTH ///////////////////////////////
  auth,

  login: (email, password) => {
    return auth.signInWithEmailAndPassword(email, password);
  },
  signup: (email, password) => {
    return auth.createUserWithEmailAndPassword(email, password);
  },
  signOut: () => {
    return auth.signOut();
  },

  checkUserAuth: user => {
    return auth.onAuthStateChanged(user);
  },

  anonymousSignIn: () => {
    return auth.signInAnonymously();
  },

  /////////////////////// FIRESTORE ///////////////////////////////
  db,
  firestore,
  //----------------- USERS -----------------

  createNewUser: userData => {
    return db
      .collection("users")
      .doc()
      .set(userData);
  },

  createUserWithId: userData => {
    return db
      .collection("users")
      .doc(userData.id)
      .set(userData);
  },

  user: userId => {
    return db.collection("users").doc(userId);
  }
};

export default Firebase;
