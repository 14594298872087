import React, { useState } from "react";
import Select from "react-select";
import { Button } from "@theme-ui/components";
import { useData } from "../../app/context/data";
import styled from "@emotion/styled";
import { participants } from "../../app/context/data/participants";

const SelectParticipant = () => {
  const { startHotSeat, endSession } = useData();
  const [selectedParticipant, setSelectedParticipant] = useState("");

  const handleNameChange = (selection) => {
    selection && setSelectedParticipant(selection.value);
  };

  return (
    <div>
      <h2>Select a participant to start the session</h2>
      <Select
        className="name"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        name="name"
        options={participants.sort().map((p) => ({ value: p, label: p }))}
        onChange={handleNameChange}
      />
      <BottomSection>
        <Button className="endButton" onClick={() => endSession()}>
          End Session
        </Button>
        <Button
          className="submitButton"
          variant={"primary"}
          onClick={() => startHotSeat(selectedParticipant)}
        >
          Submit
        </Button>
      </BottomSection>
    </div>
  );
};

export default SelectParticipant;

const BottomSection = styled.div`
  text-align: center;
  .submitButton {
    margin: 10px;
    width: 120px;
  }
  .endButton {
    margin: 10px;
    width: 120px;
    background-color: #989898;
    :hover {
      background-color: #333;
    }
    /* color: #333; */
  }
`;
